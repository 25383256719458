import React from "react"

import Layout from "../components/layout"
import BookImage from '../components/bookImage';
import MeImage from '../components/meImage';
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <div className="wrapper">
      <div className="home-image">
        <MeImage />
      </div>
      <div className="summary">
        <p>
          Failed philosopher. Former librarian. I like writing code. I write regular words as well.
        </p>
        <p>
          Author of <a href="https://pragprog.com/book/es6tips/simplifying-javascript"> Simplifying JavaScript.</a>
        </p>
        <p>
          On twitter <a href="https://twitter.com/joesmorgan">@joesmorgan</a>.
        </p>
      </div>
    </div>
  </Layout>
)

export default IndexPage
